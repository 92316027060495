<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="企业名称:">
        <el-input v-model.trim="searchForm.compName" clearable placeholder="请输入"/>
      </el-form-item>
      <el-form-item label="统计日期:">
        <el-input v-model.trim="searchForm.statisticsDate" clearable placeholder="请输入"/>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="stockMonthlyReporting"
      ref="xTable"
      :loading="loading"
      :data="tableData"
      :footer-method="footerMethod"
      :print-config="{}"
      border
      show-footer
    >
      <template #buttons>
        <el-button size="small" icon="el-icon-printer" type="primary" @click="printSelectEvent">打印</el-button>
      </template>
      <vxe-column field="materialName" title="材料名称" min-width="100" />
      <vxe-column field="compName" title="企业名称" min-width="170" />
      <vxe-colgroup title="初期库存">
        <vxe-column field="openingInventoryMount" title="数量(吨)" min-width="100" />
        <vxe-column field="openingInventoryMoney" title="金额(元)" min-width="100" />
      </vxe-colgroup>
      <vxe-colgroup title="本月入库">
        <vxe-column field="monthEnterMount" title="数量(吨)" min-width="100" />
        <vxe-column field="monthEnterMoney" title="金额(元)" min-width="100" />
      </vxe-colgroup>
      <vxe-colgroup title="本月发出(砂浆)">
        <vxe-column field="monthOutMount" title="数量(吨)" min-width="100" />
        <vxe-column field="monthOutMoney" title="金额(元)" min-width="100" />
      </vxe-colgroup>
      <vxe-colgroup title="期末库存">
        <vxe-column field="endingInventoryMount" title="数量(吨)" min-width="100" />
        <vxe-column field="endingInventoryMoney" title="金额(元)" min-width="100" />
      </vxe-colgroup>
    </BaseTable>
  </PageContainer>
</template>

<script>
import {
  getTransceiverStorageReport
} from '@/apis/stock/stockMonthlyReporting'
export default {
  data () {
    return {
      searchForm: {
        compName: '',
        statisticsDate: XEUtils.toDateString(Date.now(), 'yyyy-MM')
      },
      // 表格相关
      tableData: [],
      totalRecord: {
        endingInventoryMoney: '',
        endingInventoryMount: '',
        materialName: '',
        monthEnterMoney: '',
        monthEnterMount: '',
        monthOutMoney: '',
        monthOutMount: '',
        openingInventoryMoney: '',
        openingInventoryMount: ''
      },
      loading: false,
      editData: null,
      detailVisible: false,
      sortVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },
    onSearch () {
      this.initList()
    },
    async initList () {
      this.loading = true
      const res = await getTransceiverStorageReport(this.searchForm)
      this.loading = false
      if (res.code == 200) {
        this.tableData = res.data.recordList
        this.totalRecord = res.data.totalRecord
      } else {
        this.$message.error(res.msg)
      }
    },
    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },
    footerMethod ({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if ([
            'endingInventoryMoney',
            'endingInventoryMount',
            'materialName',
            'monthEnterMoney',
            'monthEnterMount',
            'monthOutMoney',
            'monthOutMount',
            'openingInventoryMoney',
            'openingInventoryMount'
          ].includes(column.property)) {
            return this.totalRecord[column.property]
          }
          return null
        })
      ]
    },
    printSelectEvent () {
      this.$refs.xTable.openPrint()
    }
  }
}
</script>
